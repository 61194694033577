const tabs = ["general", "verification"];

const useProfileData = () => {
	const route = useRoute();
	const localPath = useLocalePath();
	const { open } = useFunrizeModals();
	const { data: appInitData } = useAppInitData();
	const { refresh, data: pageData } = useAsyncFetch({
		path: "/rest/page/profile/",
		method: "get",
		options: {
			cached: true,
			immediate: false
		}
	});
	const { logoutUser } = useLogout();

	const activeTab = ref(tabs[0]);
	const verificationState = ref(false);

	const email = computed(() => appInitData.value?.email);
	const phone = computed(() => appInitData.value?.phone);
	const isProfileCompleted = computed(() => appInitData.value?.profileCompleted);
	const hasDeposits = computed(() => appInitData.value?.hasDeposits);
	const isD1User = computed(() => appInitData.value?.depositsCount === 1);
	const isVerificationCompleted = computed(() => appInitData.value?.fraudDetector?.verificationCompleted);
	const isShowVerificationAlarm = computed(
		() => isD1User.value && verificationState.value && !isVerificationCompleted.value
	);

	const responsiblePlayData = computed(() => pageData?.value?.payload?.responsiblePlay);

	const activityReminderValue = computed(() => responsiblePlayData?.value?.activityReminderValue);
	const activityReminderAllowedValues = computed(() => responsiblePlayData?.value?.activityReminderAllowedValues);

	const purchaseLimitPeriod = computed(() => responsiblePlayData?.value?.purchaseLimitPeriod);
	const purchaseLimitValue = computed(() => responsiblePlayData?.value?.purchaseLimitValue);
	const purchaseLimitMaxDayAmount = computed(() => responsiblePlayData?.value?.purchaseLimitMaxDayAmount);
	const purchaseLimitMaxWeekAmount = computed(() => responsiblePlayData?.value?.purchaseLimitMaxWeekAmount);
	const purchaseLimitMaxMonthAmount = computed(() => responsiblePlayData?.value?.purchaseLimitMaxMonthAmount);
	const purchaseLimitMinDayAmount = computed(() => responsiblePlayData?.value?.purchaseLimitMinDayAmount);
	const purchaseLimitMinWeekAmount = computed(() => responsiblePlayData?.value?.purchaseLimitMinWeekAmount);
	const purchaseLimitMinMonthAmount = computed(() => responsiblePlayData?.value?.purchaseLimitMinMonthAmount);

	const selfExclusionValue = computed(() => responsiblePlayData?.value?.selfExclusionValue);
	const selfExclusionAllowedValues = computed(() => responsiblePlayData?.value?.selfExclusionAllowedValues);
	const coolOffAllowedValues = computed(() => responsiblePlayData?.value?.coolOffAllowedValues);

	const handleOpenProfilePopup = () => {
		if (appInitData.value?.accountStatus === "DUPLICATE") {
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
			return;
		}

		window?.$cash?.$router?.push("/cash/account/?isOutsideCash=true");
	};

	const handleLogout = async () => {
		await logoutUser();
		dispatchGAEvent({ event: "logout", location: "settings" });
		localStorage.removeItem("showedDailyWheelPopup");
		localStorage.removeItem("showVerificationAlarm");
		localStorage.removeItem("isFraudDetectorRunning");
		localStorage.removeItem("showTowerModalInGamePopup");

		navigateTo(localPath("/"), { external: true });
	};

	const hideVerificationAlarm = () => {
		if (isD1User.value) {
			verificationState.value = false;
			localStorage.setItem("showVerificationAlarm", "false");
		}
	};

	const handleChangeTab = (tab: string) => {
		if (tab === "verification") {
			hideVerificationAlarm();
		}
	};

	const showRealityCheckNotification = (intervalVal: number) => {
		open("LazyOModalAccountRealityCheckNotification", { intervalVal });
	};

	const updateRealityCheckInterval = (intervalInMinutes: string | null = null) => {
		clearInterval(window.realityCheckInterval);
		window.realityCheckInterval = null;

		if (intervalInMinutes && +intervalInMinutes === 0) {
			return;
		}

		const intervalVal = intervalInMinutes || activityReminderValue.value || 0;
		const interval = +intervalVal * 60 * 1000;

		if (interval) {
			window.realityCheckInterval = setInterval(() => {
				showRealityCheckNotification(+intervalVal || 0);
			}, interval);
		}
	};

	onMounted(() => {
		if (localStorage.getItem("showVerificationAlarm")) {
			verificationState.value = localStorage.getItem("showVerificationAlarm") === "true";
		} else {
			verificationState.value = true;
			localStorage.setItem("showVerificationAlarm", "true");
		}

		if (route.hash.includes("verify")) {
			activeTab.value = "verification";
			hideVerificationAlarm();
		}
	});

	return {
		activeTab,
		tabs,
		pageData,
		responsiblePlayData,
		activityReminderValue,
		activityReminderAllowedValues,
		purchaseLimitPeriod,
		purchaseLimitValue,
		purchaseLimitMaxDayAmount,
		purchaseLimitMaxWeekAmount,
		purchaseLimitMaxMonthAmount,
		purchaseLimitMinDayAmount,
		purchaseLimitMinWeekAmount,
		purchaseLimitMinMonthAmount,
		selfExclusionValue,
		selfExclusionAllowedValues,
		coolOffAllowedValues,
		email,
		phone,
		isProfileCompleted,
		hasDeposits,
		isShowVerificationAlarm,
		refresh,
		updateRealityCheckInterval,
		handleOpenProfilePopup,
		handleLogout,
		handleChangeTab
	};
};

export default useProfileData;
